import { mapState } from "vuex";
import flag_nl from "../assets/images/flags/nl.svg";
import flag_de from "../assets/images/flags/de.svg";
import flag_be from "../assets/images/flags/be.svg";
import flag_en from "../assets/images/flags/int.svg";
import flag_fr from "../assets/images/flags/be.svg";
import flag_ch from "../assets/images/flags/ch.svg";
import flag_int from "../assets/images/flags/int.svg";
import flag_at from "../assets/images/flags/at.svg";
const ENV = process.env.VUE_APP_ENV;
export default {
  data: () => ({
    isAppMobile: false,
    isAppTablet: false,
    debug: false,
    environment: ENV,
    scrollPosition: 0,
    fullUrl: null,
    emailInviteId: {
      NL: "1004186",
      DE: "1004203",
      BE: "1004242",
    },
    flags: {
      at: flag_at,
      be: flag_be,
      be_fr: flag_be,
      de: flag_de,
      nl: flag_nl,
      en: flag_en,
      fr: flag_fr,
      ch: flag_ch,
      int: flag_int,
    },
  }),
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
    window.removeEventListener("scroll", this.updateScroll);
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    window.addEventListener("scroll", this.updateScroll);
  },
  computed: {
    ...mapState("liApiStore", ["relations", "clangID", "timeSlots"]),
    ...mapState("liApiContent", ["website"]),
    language: {
      get() {
        return this.$route.params.language != undefined ? `${this.$route.params.language}` : null;
      },
      set(val) {
        if (this.language != val) {
          if (this.$route.meta.showAdminNav == true) {
            // ADMIN LANGUAGE CHANGE
            this.$router.push(`/${this.$route.params.code}/${val}`);
          } else {
            // CLAIM LANGUAGE CHANGE
            this.$router.push(`/${val}`);
          }
        }
      },
    },
    navigationItems() {
      return this.$store.getters["liApiContent/getCollection"](`main_navigation_${this.language}`);
    },
    navigationContent() {
      let result = null;
      if (this.navigationItems != null && this.navigationItems.length > 0) {
        let block = JSON.parse(this.navigationItems[0].content);
        if (block.type === "html") {
          result = JSON.parse(block.html);
        }
      }
      return result;
    },
    defaultTexts() {
      return this.$store.getters["liApiContent/getCollection"](`default_texts_${this.language}`);
    },
    defaultTextContent() {
      let result = null;
      if (this.defaultTexts != null) {
        let block = JSON.parse(this.defaultTexts[0].content);
        if (block.type === "html") {
          result = JSON.parse(block.html);
        }
      }
      return result;
    },
    footerItems() {
      return this.$store.getters["liApiContent/getCollection"](`main_footer_${this.language}`);
    },
    footerContent() {
      let result = null;
      if (this.footerItems != null) {
        let block = JSON.parse(this.footerItems[0].content);
        if (block.type === "html") {
          result = JSON.parse(block.html);
        }
      }
      return result;
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getRelationTypeLabel(_relationshipTypeId) {
      if (_relationshipTypeId != undefined && _relationshipTypeId != "") {
        let label = this.defaultTextContent.relationTypes.find((_label) => String(_label.value) == String(_relationshipTypeId));
        return label != undefined ? label.name : null;
      } else {
        return "";
      }
    },
    getRelationTypeSlots(_relationshipTypeId) {
      if (_relationshipTypeId != undefined && _relationshipTypeId != "") {
        let slots = this.defaultTextContent.relationTypes.find((_type) => String(_type.value) == String(_relationshipTypeId));
        return slots != undefined ? slots.accesToTimeSlots : null;
      } else {
        return "";
      }
    },
    liFormatText(_text, _arguments) {
      for (var arg in _arguments) {
        _text = _text.replace("{" + arg + "}", _arguments[arg]);
      }
      return _text;
    },
    localeDateFormat(_date, _type = "default", _full = "default") {
      let dateObj = "",
        options = {};
      if (_type == "manual") {
        let dateString = _date.includes(".") ? _date.replaceAll(".", "-") : _date;
        let [day, month, year] = dateString.split("-");
        dateObj = new Date(+year, +month - 1, +day);
      } else {
        dateObj = new Date(_date);
      }
      if (_full != "default") {
        options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
      } else {
        options = {
          day: "2-digit",
          year: "numeric",
          month: "2-digit",
        };
      }
      var formatter;
      if (this.language.toLowerCase() == "be") {
        formatter = new Intl.DateTimeFormat("Nl", options);
      } else {
        formatter = new Intl.DateTimeFormat(this.language.toUpperCase(), options);
        // formatter = new Intl.DateTimeFormat("DE", options);
      }
      return formatter.format(new Date(dateObj));
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    fetchURL(_path = null) {
      if (_path == null) {
        const path = this.$route.path.split("/");
        if (this.$route.name != null && this.$route.path == "/") {
          this.$router.replace(`/en/notallowed`);
        } else {
          if (this.environment == "development") {
            path.shift();
            path.shift();
            console.log(`/${path.join("/")}`);
            this.fullUrl = `/${path.join("/")}`;
          } else {
            path.shift();
            console.log(`/${path.join("/")}`);
            this.fullUrl = `/${path.join("/")}`;
          }
        }
      } else {
        this.fullUrl = _path;
      }
    },
    fetchAppDefaultTexts() {
      if (this.language != null) {
        this.$store.dispatch("liApiContent/GET_COLLECTION", {
          request: {
            object: "li_static_blocks_events",
            where: `status [=>] 1 || language [=>] ${this.language} || block_identifier [=>] default_texts`,
            orderBy: "sequence ASC",
            select: "content",
          },
          collectionName: `default_texts_${this.language}`,
        });
      }
    },
    fetchNavigationItems() {
      this.$store.dispatch("liApiContent/GET_COLLECTION", {
        request: {
          object: "li_static_blocks_events",
          where: `status [=>] 1 || language [=>] ${this.language} || block_identifier [=>] main_navigation`,
          orderBy: "sequence ASC",
          select: "content",
        },
        collectionName: `main_navigation_${this.language}`,
      });
    },
    fetchPageData() {
      this.$store.dispatch("liApiContent/GET_PAGE", {
        object: "li_pages_events",
        where: `status [=>] 1 || language [=>] ${this.language} || fullurl [=>] ${this.fullUrl}`,
        orderBy: "name ASC",
        select: "pagetitle,pagedescription,content,language,fullurl,name",
      });
    },
    fetchFooterItems() {
      this.$store.dispatch("liApiContent/GET_COLLECTION", {
        request: {
          object: "li_static_blocks_events",
          where: `status [=>] 1 || language [=>] ${this.language} || block_identifier [=>] footer`,
          orderBy: "sequence ASC",
          select: "content",
        },
        collectionName: `main_footer_${this.language}`,
      });
    },
    getRandomFromArray(arr, n) {
      var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
      if (n > len) throw new RangeError("getRandom: more elements taken than available");
      while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
      }
      return result;
    },
    /**
     * [!] USE WITH CAUTION [!]
     * Returns a deep find of a key from a dot notation string
     * @param {obj} Object the object where to look in for the key
     * @param {is} String the  "key.key.key" notation for the key to look for in the given object
     *
     */
    // obj,'1.2.3' -> multiIndex(obj,['1','2','3'])
    pathIndex(obj, is) {
      return this.multiIndex(obj, is.split("."));
    },
    // obj,['1','2','3'] -> ((obj['1'])['2'])['3']
    multiIndex(obj, is) {
      return is.length ? this.multiIndex(obj[is[0]], is.slice(1)) : obj;
    },
    triggerHome() {
      if (this.$route.name != "Home") {
        this.$router.push("/");
      }
    },
    /**
     * Returns a 'CLEAN' string removing (white) space, all lowercase and leaving only alphanumeric values and dashes
     *
     * @param {date} String The string that needs to be formatted
     */
    liStripString(_string) {
      if (_string != null && _string != "") {
        return _string
          .trim()
          .toLowerCase()
          .replace(/[^\w\s-]/g, "")
          .replace(/^-+|-+$/g, "");
      } else {
        return "";
      }
    },
    liGetInitials(_name) {
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

      let initials = [..._name.matchAll(rgx)] || [];

      initials = ((initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")).toUpperCase();

      return initials;
    },
    stripTags(value) {
      if (!value) return "";
      value = value.toString();
      return value.replace(/(<([^>]+)>)/gi, "");
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
    onResize() {
      this.isAppMobile = window.innerWidth < 600;
      this.isAppTablet = window.innerWidth > 600 && window.innerWidth < 1500;
    },
    matchesOR(_item, _criteria) {
      for (var n = 0; n < _criteria.length; n++) {
        if (_criteria[n]["Values"].indexOf(_item[_criteria[n]["Field"]]) > -1) {
          return true;
        }
      }
    },
    matchesAND(_item, _criteria) {
      let count = 0;
      for (var n = 0; n < _criteria.length; n++) {
        if (_criteria[n]["Field"].includes(".")) {
          // MULTI LEVEL INDEX SEARCH -> should only return a string ..... SHOUD
          const value = this.pathIndex(_item, _criteria[n]["Field"]);
          if (typeof value === "string") {
            if (_criteria[n]["Values"].includes(value)) {
              count++;
            }
          }
        } else {
          // value is string
          if (typeof _item[_criteria[n]["Field"]] === "string") {
            if (_criteria[n]["Values"].indexOf(_item[_criteria[n]["Field"]]) > -1) {
              count++;
            }
          }
          // value is object
          if (typeof _item[_criteria[n]["Field"]] === "object") {
            const found = _item[_criteria[n]["Field"]].some((r) => _criteria[n]["Values"].includes(r));
            if (found) {
              count++;
            }
          }
        }
      }
      return count == _criteria.length;
    },
    arrayFilter(_data, _criteria, _type) {
      let matches = [];
      for (var i = 0; i < _data.length; i++) {
        // Determine if the current item matches the filter criteria
        if (_type == "OR") {
          if (this.matchesOR(_data[i], _criteria)) {
            matches.push(_data[i]);
          }
        } else if (_type == "AND") {
          if (this.matchesAND(_data[i], _criteria)) {
            matches.push(_data[i]);
          }
        }
      }
      return matches;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    deCapitalizeFirstLetter(string) {
      return string.charAt(0).toLowerCase() + string.slice(1);
    },
  },
};
